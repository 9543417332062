.stats {
  padding-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.rater {
  top: 0;
  height: 28px;
}

.stat {
  border-left: 1px solid var(--borderColor);
  padding: 0 16px;
}

.stat:first-child {
  border-left: none;
  padding-left: 0;
}
