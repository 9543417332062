@import "../../../css/common/colors.css";

.media-iframe {
  width: 800px;
  max-width: 100%;
  min-width: 100%;
  min-height: 300px;
  margin: 0;
  flex-shrink: 0;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .media-iframe iframe {
  min-width: 100%;
  max-width: 100%;
} */

.media-unstyled {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.media-unstyled iframe {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  background: #333;
}

@media screen and (max-width: 480px) {
  .media-iframe {
    width: calc(100vw - 24px);
    min-height: 0;
  }
}

.media-img {
  background: var(--colorBlack);
  object-fit: contain;
  max-width: 100%;
  max-height: 80vh;
}

.insta-embed {
  width: 580px;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background: #fff;
  max-width: calc(100vw - 24px);
}

.insta-embed iframe {
  border: none !important;
  margin-left: 1px !important;
}
