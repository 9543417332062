.like-btn {
  padding: 0;
  margin: 0;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-top: 5px;
  border-radius: 100%;
  cursor: grabbing;
  font-size: 16px;
}

.fist-bump g g {
  stroke: var(--colorBlue);
}

.has-liked-btn {
  composes: like-btn;
  background: var(--colorOrange);
  color: #fff;
}
