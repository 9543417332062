.user-feed-card {
  position: fixed;
  width: calc((100vw - 68px) / 4);
  max-width: 276px;
  margin-top: 10px;
}

@media screen and (max-width: 840px) {
  .user-feed-card {
    width: 100%;
    position: relative;
    margin-bottom: -12px;
    max-width: none;
    margin-bottom: 20px;
  }
}
.user-icon,
.user-img {
  width: 115px;
  height: 115px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
  border-radius: 100%;
  overflow: hidden;
}

.user-icon {
  font-size: 64px;
  text-align: center;
  position: relative;
  height: 100px;
  width: 100px;
  background: var(--colorBlue);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.user-name-link {
  margin: auto;
}

.user-name {
  display: inline-block;
  margin: 20px auto 0;
}

.stats {
  padding-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: center;
}
.stats h4 {
  margin: 0;
  line-height: 1;
}
.stats h6 {
  font-size: 0.8rem;
  margin: 2px 0 0;
}

.stat {
  border-left: 1px solid var(--borderColor);
  padding: 0 16px;
}

.stat:first-child {
  border-left: none;
  padding-left: 0;
}

.stat:last-child {
  padding-right: 0;
}

@media screen and (max-width: 960px) and (min-width: 840px) {
  .stats {
    display: block;
  }

  .stat,
  .stat:first-child,
  .stat:last-child {
    border: none;
    text-align: center;
    padding: 5px 16px;
  }
}
