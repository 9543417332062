.feed-item {
  padding: 0 10px;
  margin: 0 -20px;
}

.feed-item-card {
  margin-bottom: 0;
}

.feed-item-copy {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.feed-item-copy h4 {
  margin-bottom: 8px;
  margin-right: 10px;
}

@media screen and (max-width: 840px) {
  .feed-item-copy {
    padding: 0 10px 0 0;
  }
}

.feed-item-img {
  position: relative;
}

@media screen and (max-width: 480px) {
  .feed-item-img {
    height: 180px;
  }
}

.user-chip {
  margin: 0 0 -5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-img {
  border-radius: 100%;
  overflow: hidden;
  margin: 0 10px 0 0;
  width: 40px;
  height: 40px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  flex-shrink: 0;
}

.user-icon {
  border-radius: 100%;
  overflow: hidden;
  margin: 0 10px 0 0;
  width: 40px;
  height: 40px;
  font-size: 30px;
  background: var(--colorBlue);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-name {
  margin: 0 0 -5px;
}

.date {
  display: block;
  margin-top: 5px;
  font-size: 0.8rem;
}

.main-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  object-fit: cover;
  display: block;
}

.feature-list-title {
  text-transform: capitalize;
  margin-bottom: -10px;
  /* border-bottom: 1px solid #eee; */
  margin-right: 10px;
}

.comments {
  border-top: 1px solid #e6e1e1;
  margin: 18px -18px -20px;
}
